<script setup lang="ts">
const navigationMenuStore = useNavigationMenuStore();

const { Texts } = useTexts();
</script>

<template>
  <div class="w-screen p-6 border-t mt-20">
    <div class="flex flex-row items-center justify-between">
      <div class="flex flex-row items-center space-x-4">
        <nuxt-link
          v-for="item in navigationMenuStore.legalItems"
          :key="item.title"
          :to="item.link"
          class="text-sm hover:underline underline-offset-[3px] text-black/80"
        >
          {{ item.title }}
        </nuxt-link>
      </div>

      <div v-if="Texts.isC" class="space-x-4 text-black/80">
        <nuxt-link to="https://www.instagram.com/meinseelenspiegel.de">
          <icon
            name="mdi:instagram"
            size="24px"
            class="hover:scale-105 transition-transform duration-200"
          />
        </nuxt-link>
        <nuxt-link to="https://www.tiktok.com/@meinseelenspiegel">
          <icon
            name="ic:baseline-tiktok"
            size="24px"
            class="hover:scale-105 transition-transform duration-200"
          />
        </nuxt-link>
      </div>
    </div>
  </div>
</template>
